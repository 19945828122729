import React, {useRef, useEffect, useState, useContext, useTransition} from 'react';
import {FormContext} from "../../../context/FormContext";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import css from './checkbox.module.css';
import { LPEPrepCourseDateSelector } from './../../FormElements/LPEPrepCourseDateSelector/LPEPrepCourseDateSelector';


export const CheckBox = ({name, price, prettyName, descr, register, required,validate, setSendable}) => {

    const { addToPriceList , removeFromPriceList} = useContext(FormContext);
    const { t }  = useTranslation();
    const {formType} = useContext(FormContext);

    const [isChecked,toggleChecked] = useState(false);
    const [selectedCourseDate,setSelectedDate] = useState('');
    const [sendCheckBox,setSendCheckBox] = useState(false);
    const [firstRender,setFirstRender] = useState(true);

    const isFirstRender = useRef(true);

useEffect(() => {
    // Voer de useEffect niet uit tijdens de eerste render
    if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
    }

    console.log("komt ie hier");

    // if (sendCheckBox) {
    //     setSendable(true);
    // } else {
    //     setSendable(false);
    // }
}, [sendCheckBox]);

    useEffect(() => {
        //if the checkbox is checked and a coursedate is selected its ready to be send with the form
        if(name == "lpePrepCourse"){
            if(selectedCourseDate){
                setSendable(true)
            }
            else {
                setSendable(false)
            }
        }
    },[selectedCourseDate]);

    useEffect(() => {
        //if the checkbox is checked and a coursedate is selected its ready to be send with the form
        if(!firstRender){
            if(name == "lpePrepCourse"){
                if(isChecked &&  !selectedCourseDate){
                    console.log("ditmomet niet"+name)
                    
                    setSendable(false)
                }
            }
            else {
                console.log("first reder stay  true")
                setSendable(true)
            }
        }
        else {
            console.log("het is de eerste render")
            setSendable(true)
        }
        setFirstRender(false)
    },[isChecked]);

    useEffect(()=>{
        if (isChecked){
            addToPriceList(prettyName,price);
        }
        if (!isChecked){
            removeFromPriceList(prettyName);
        }



    },[isChecked])



    const inputChange = (e) => {
       toggleChecked(e.target.checked);
    }


    return (
        <>
            <div className={css['input-field']}>
                <label htmlFor={name}>{prettyName}
                <p className={css['description']}>{descr}</p>
                <div className={css['label-checkbox-wrapper']}>
                   + Add {prettyName} <input type="checkbox"
                                            name={name}
                                            id={name}
                                            onChange={(e)=>{inputChange(e)}}
                />
                {name === 'lpePrepCourse' && isChecked && <LPEPrepCourseDateSelector     
                name='prep-course-date'
                register={register}
                required={required}
                validate={validate}
                setSelectedDate={setSelectedDate}
                /> }
                </div>
                </label>
            </div>



        </>
    )
}


export default CheckBox;