import React, {useEffect, useState, useContext, useTransition} from 'react';
import {FormContext} from "../../../context/FormContext";
import i18next from "i18next";
import {useTranslation} from "react-i18next";



export const LPEPrepCourseDateSelector = ({register, name, required, validate,setSelectedDate}) => {


    const axios = require('axios').default;

    const { t }  = useTranslation();
    const {formType} = useContext(FormContext);

    const [courseDates, setCourseDates] = useState({});
    const [selectedCourseDate, setSelectedCourseDate] = useState('none');

    useEffect(()=>{
        getCourseDates();
    },[])

    const getCourseDates = async () => {
        try {
            const dates = await axios.get(`https://www.atc-comm.com/api/get-course-dates.php?type=lpePrepCourse`, {
  'Accept': 'application/json',})
            setCourseDates(dates.data);
        }
        catch (e) {

            console.log(e);
        }
    }

    const getReadableDate = (timestamp) =>  {

        const milliseconds = timestamp * 1000 // 1575909015000

        const dateObject = new Date(milliseconds)
        const humanDateFormat = dateObject.toLocaleString().substring(0,dateObject.toLocaleString().length-8) //2019-12-9 10:30:15
        return humanDateFormat;
    }

    const handleCourseDateChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedCourseDate(selectedValue);
        setSelectedDate(selectedValue);
    };


    return (
        <select
            defaultValue="default"
            name="rt-course-date"
            id="rt-course-date" {...register(name, {
            required:required,
            validate:validate,})}
            onChange={handleCourseDateChange}
        >
            
            <option value="default" disabled>{t('chooseDate')}</option>

            {Object.keys(courseDates).length > 0 && courseDates.map((date)=>

                    /*<option
                        key={date.id}
                        value={`${date.start_date}|${date.time}`}
                    >{`${getReadableDate(date.start_date)} - ${date.time}`}</option>*/
                <option
                    key={date.id}
                    value={`${date.start_date}`}
                >{`${getReadableDate(date.start_date)} - ${date.time}`}</option>

            )}
        </select>
    )
}


export default LPEPrepCourseDateSelector;