import styles from "../../components/Main/Main.module.css";
import React, {useContext,useEffect,useState} from "react";
import { RTCourseDateSelector } from './../FormElements/RTCourseDateSelector/RTCourseDateSelector';
import { LPEPrepCourseDateSelector } from './../FormElements/LPEPrepCourseDateSelector/LPEPrepCourseDateSelector';
import {FormContext} from "../../context/FormContext";
import { CheckBox } from "../FormElements/CheckBox/CheckBox";

export function FormElement( props ) {

    const { } = useContext(FormContext);
    const [isSendable,setSendable] = useState(true);
    
    useEffect(()=>{
        if(isSendable){
            props.setShowButton(true)
        }
        else {
            props.setShowButton(false)
        }
    },[isSendable]);


    return (<section className={'contentBox '+styles['main-forms']}>
        {props.formArray[props.step-1].columns.map(( column ) =>
            <div className={styles['forms-column']} key={column.title}>
                <h3>{column.title}</h3>
                {column.fields.map((field) =>
                    <div className={styles['form-field-wrapper']}  key={field.name}>
                        {field.type !== 'checkbox' && <div className={styles['field-label']}>{field.prettyName}</div>}
                        {
                            field.type === 'text' && <input type={field.type} {...props.register(field.name, {
                                required:field.required,
                                maxLength:50,
                                validate: field.validate
                            })} />
                        }
                        {
                            field.type === 'select' && <select name="country"
                                                               {...props.register(field.name, {
                                                                   required:field.required,
                                                                   maxLength:50,
                                                                   validate: field.validate
                                                               })}
                            >
                                {field.options.map((option) =>
                                    <option value={option.value} key={option.name}>{option.name}</option>
                                )}
                            </select>
                        }
                        {
                            field.type === 'checkbox' &&
                            <CheckBox
                                name={field.name}
                                prettyName={field.prettyName}
                                price={field.price}
                                descr = {field.descr}
                                register={props.register}
                                required={field.required}
                                validate={field.validate}
                                setSendable={setSendable}
                            />
                        }
                        {
                            field.type === 'date' && <input type={field.type} min="1900-01-01" max="2020-12-31" {...props.register(field.name, {
                                required:field.required,
                                validate: field.validate

                            })} />
                        }
                        {
                            field.type === 'course-date' &&
                            <RTCourseDateSelector
                                register={props.register}
                                name={field.name}
                                required={field.required}
                                validate={field.validate}
                               />
                        }
                        {
                            field.type === 'lpe-prep-course-date' &&
                            <LPEPrepCourseDateSelector
                                register={props.register}
                                name={field.name}
                                required={field.required}
                                validate={field.validate}
                               />
                        }




                        {props.errors[field.name] && <p>{props.errors[field.name].message}</p>}
                    </div>
                )}
            </div>
        )}
    </section>);
}

export default FormElement;