import './App.css';
import  Header from './components/Header/Header.js';
import Main from './components/Main/Main.js';
import Footer from './components/Footer/Footer.js';
import { Routes, Route, Link } from 'react-router-dom';
import React from "react";



function App() {
    const [activeStep, setActiveStep] = React.useState({'step':1,'title':'Overview'});


  return (
    <div className="App">
        <Header activeStep={activeStep} />
        <Routes>
            <Route path="/" element={<Main
                title="not found"
                subTitle="Error"
            />} />
            <Route path="/lpe-exam" element={<Main
                title="Language Proficiency Exam"
                subTitle="Enrollment Form"
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                type="lpeExam"
            />} />
            <Route path="/lpe-preparation-course" element={<Main
                title="LPE Preparation Course"
                subTitle="Enrollment Form"
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                type="lpePrepCourse"
            />} />
            <Route path="/lpe-placement-test" element={<Main
                title="LPE Placement Test"
                subTitle="Enrollment Form"
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                type="lpePlacementTest"
            />} />
            <Route path="/rt-course-vfr" element={<Main
                title="VFR RT Course"
                subTitle="Enrollment Form"
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                type="vfrRT"
            />} />
            <Route path="/rt-course-ifr" element={<Main
                title="IFR RT Course"
                subTitle="Enrollment Form"
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                type="ifrRT"
            />} />
            <Route path="/rt-course-refresher" element={<Main
                title="RT Refresher Course"
                subTitle="Enrollment Form"
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                type="refresherRT"
            />} />
        </Routes>
        <Footer />
    </div>
  );
}

export default App;
