import React, {useState, useContext} from 'react';
import i18next from 'i18next';
import langSelectorStyles from './languageSelector.module.css';
import {FormContext} from "../../context/FormContext";
import Form from "../../Form/Form";



export function LanguageSelector() {

    const { rerenderForm, setSelectedLanguage } = useContext(FormContext);
    const [selectedLang, setSelectedLang] = React.useState(i18next.language)

 /*   const switchLang = async (e) => {


            const selectedLanguage = e.target.value;
            //setSelectedLang(selectedLanguage)
            const update = await i18next.changeLanguage(selectedLanguage);



    }*/

    return (
        <select name="languageSelector"
                id="languageSelector"
                className={langSelectorStyles['language-selector']}
                defaultValue = {i18next.language}
                onChange={(e)=>{setSelectedLanguage(e.target.value)}}>
            <option value="nl">🇳🇱 Nederlands</option>
            <option value="en">🇬🇧 English</option>
        </select>
    );
}