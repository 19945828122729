import React, {useContext, useEffect} from 'react';
import styles from './Main.module.css';
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { useForm } from 'react-hook-form';
import axios from "axios";
import { Form } from './../../Form/Form';
import { Link } from 'react-router-dom';
import {logDOM} from "@testing-library/react";
import { FormElement } from "../../Form/FormElement/FormElement";
import { OverviewElement } from '../../Form/OverviewElement/OverviewElement'
import { useTranslation } from "react-i18next";
import { LanguageSelector } from '../LanguageSelect/languageSelect.js';
import ReCAPTCHA from "react-google-recaptcha";
import {FormContext} from "../../context/FormContext";
import ShoppingBasket from "./ShoppingBasket/ShoppingBasket";


const lpeForm = {
    "personalFields" : [{
                "name" : "firstName",
                "prettyName" : "Firstname",
                "type" : "text",
                "required" : "Please fill in your firstname",
                "validate" : ""
            },
            {
                "name": "lastName",
                "prettyName": "Lastname",
                "type": "text",
                "required": "Please fill in your lastname",
                "validate" : ""
            },
            {
                "name": "email",
                "prettyName": "E-mail",
                "type": "text",
                "required": "Please fill in your E-mail",
                "validate": (value) => value.includes('@')
            }],
    "addressFields" : [{
            "name": "address",
            "prettyName": "Address",
            "type": "text",
            "required": "Please fill in your address",
            "validate" : ""
        },
        {
            "name": "postcode",
            "prettyName": "Postcode",
            "type": "text",
            "required": "Please fill in  your postcode",
            "validate" : ""
        },
        {
            "name": "city",
            "prettyName": "City",
            "type": "text",
            "required": "Please fill in your city",
            "validate" : ""
        },
        {
            "name": "country",
            "prettyName": "Country",
            "type": "select",
            "required": "Please fill in your country",
            "validate" : "",
            "option" : [
                {
                    "name": "Netherlands",
                    "value":  "Netherlands"
                },
                {
                    "name": "United Kingdom",
                    "value":  "United Kingdom"
                }
            ]

        }],
};


function Main(props,{type}) {

    const {stepForward,setBack,setStep1Content,step1Content, completeForm, currentStep, rerenderForm, priceList, priceTotal}  = useContext(FormContext)
    const { t } = useTranslation();


    //const [step1Content, setStep1Content,] = React.useState(completeForm);

    const saveData = function saveForm(data){

        if (data){
            const saveThisForm = completeForm.map(( form ) => {
                form.columns.map(( column ) => {
                    column.fields.map(( field ) => {
                        /*data.find(( propertie ) => {
                            return propertie.
                        })*/

                        field['savedValue'] = data[field.name];
                    });
                });
                return form;


            });


            stepForward({'step':2,'title':'Overview'});


            async function postData(  allData ) {
                try {
                    const result = await axios.post('https://checkout.atc-comm.com/get-form.php',
                        allData, {
                        'Content-Type': 'application/json'
                    });

                } catch ( e ) {
                    console.error( e );
                }
            }

            if (data['accept-terms']) {
                postData( step1Content );
                stepForward();
            }
        }



    }

    const showShoppingBasket = () => {


        if (currentStep.step < 3 && priceTotal){
            return true;
        }
        else {
            return false;
        }

    }

    return(
        <main>
            <div className={styles['main-container']}>
                <div className="contentBox">
                    <h1 className={styles['main-h1']}>{props.title}</h1>
                    <h2 className={styles['main-h2']}>{props.subTitle}</h2>
                </div>
                <Form formArray={completeForm} step={props.activeStep.step} saveForm={saveData} setActiveStep={props.setActiveStep} savedContent={step1Content} />
            </div>
            {showShoppingBasket() && <ShoppingBasket />}


        </main>
    );
}

export default Main;