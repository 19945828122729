import styles from './overviewelement.module.css';
import React, {useContext} from "react";
import {FormContext} from "../../context/FormContext";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import {logDOM} from "@testing-library/react";



export function OverviewElement(props, {register}) {

    const {currentStep,step1Content,step2Content,completeForm, completeContent} = useContext(FormContext);
    const { t } = useTranslation();


    const getReadableDate = (timestamp) =>  {

        const milliseconds = timestamp * 1000 // 1575909015000

        const dateObject = new Date(milliseconds)
        const humanDateFormat = dateObject.toLocaleString().substring(0,dateObject.toLocaleString().length-8) //2019-12-9 10:30:15
        return humanDateFormat;
    }


    return (<section className={'contentBox '+styles['main-forms']}>
        <div className={styles['overview-wrapper']}>
            {
                completeContent.newFields.map((field)=>
                    <>
                        {field.type != 'course-date'  && field.type != 'lpe-prep-course-date' && field.value &&

                            <div key={field.name} className={styles['overview-line']}>
                                <div className={styles['label']}>{t(field.prettyName)}</div>:
                                <div className={styles['value']}>{field.value}</div>
                            </div>}

                       {/* { field.type == 'course-date' &&
                            <div key={field.name} className={styles['overview-line']}>
                                <div className={styles['label']}>{t(field.prettyName)}</div>:<div className={styles['value']}>{`${getReadableDate(field.value.split('|')[0])} - ${field.value.split('|')[1]}`}</div></div>}*/}
                        { field.type == 'course-date' || field.type == 'lpe-prep-course-date' &&
                            <div key={field.name} className={styles['overview-line']}>
                                <div className={styles['label']}>{t(field.prettyName)}</div>:<div className={styles['value']}>{getReadableDate(field.value)}</div></div>}
                    </>
                )
            }

            {currentStep.step == 2 && <div className={styles['terms-and-conditions']}>

                <input
                    type="checkbox"
                    value="accepted"
                    id="accept-terms"
                    name="accept-terms"
                    {...props.register('accept-terms',
                        {required:true,
                        validate:true}
                    )}

                />
                <label htmlFor="accept-terms"> I accept the <a href="https://www.atc-comm.com/website/docs/Algemene-voorwaarden.pdf" target="_blank">terms and conditions</a></label></div>}
        </div>

    </section>);
}

export default OverviewElement;