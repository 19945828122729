import {useForm} from "react-hook-form";
import styles from "../components/Main/Main.module.css";
import {LanguageSelector} from "../components/LanguageSelect/languageSelect";
import {BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill} from "react-icons/bs";
import { FormElement }  from  './FormElement/FormElement';
import {  OverviewElement } from './OverviewElement/OverviewElement'
import { SuccessElement }  from './SuccessElement/SuccessElement';

import ReCAPTCHA from "react-google-recaptcha";
import React, {useContext, useEffect, useState} from "react";
import {FormContext} from "../context/FormContext";
import { FormError } from './../Form/FormElements/FormError/FormError';
import { useTranslation } from "react-i18next";

export function Form( props ){
    const { handleSubmit, formState: { errors }, register} = useForm({ mode: 'onBlur' });
    const [reCaptcha, setRecaptcha] = useState(null);
    const { currentStep, stepBack, stepForward, completeForm , setStep1Content, step1Content, saveForm} = useContext(FormContext);


    const { t } =  useTranslation();
    const [showButton,setShowButton] = useState(true);

console.log("999999")
console.log(showButton)


    const onChange = (value) => {
        setRecaptcha(value)
    }

    useEffect(() => {
        console.log('recaptcha:'+reCaptcha);
    },[reCaptcha]);


    function onFormSubmit (data) {
        if (reCaptcha){

            setStep1Content(data);
            stepForward();
        }
        else {
            setRecaptcha(false);
        }
    }





    return (
        <>
            {reCaptcha === false && <FormError errorText={t('recaptchaError')} />}
            <form onSubmit={handleSubmit(onFormSubmit)} className={styles['form']}>
                {currentStep.step == 1 && <section className={styles['language-selector']}>
                    <LanguageSelector />
                </section>
                }

                {currentStep.step == 1 && <FormElement formArray={ props.formArray } step={ props.step } errors={ errors } register={ register } setShowButton={setShowButton}/>}
                {currentStep.step == 2 && <OverviewElement savedContent={ props.savedContent} step={props.step} register={ register } />}
                {currentStep.step == 3 && <SuccessElement savedContent={ props.savedContent } />}

                {currentStep.step < 3 &&
                    <section>
                        <div className={`${styles['next-prev-wrapper']}`}  >
                            {currentStep.step > 1 &&
                                <button type="button" className={'contentBox ' + styles['prev-button']} onClick={()=>stepBack()}><BsFillArrowLeftCircleFill /> Back</button>}
                                {showButton && <button type="submit" className={'contentBox ' + styles['next-button']} >Volgende  <BsFillArrowRightCircleFill /></button>}

                        </div>
                    </section> }
                <ReCAPTCHA
                    sitekey="6Lc16EoiAAAAAGnzBrKyD6cj4M8PhXA56jQFKo1J"
                    onChange={onChange}
                    size="normal"
                />
                {reCaptcha != null && <input type="hidden" name="recaptcha" value={reCaptcha}/>}

            </form>
        </>
    );
}

export default Form;