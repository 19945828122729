import React, {useContext} from 'react';
import css from './shoppingbasket.module.css';
import {FormContext} from "../../../context/FormContext";
import {useTranslation} from "react-i18next";


export const ShoppingBasket = () => {

    const { priceList, priceTotal } = useContext(FormContext);
    const { t } = useTranslation();


   /* const totalPrice = (priceList.find((item)=>{return item.name === 'total'}).price);*/


    return  (
        <div className={css['shopping-basket']}>
            <h2>{t('yourOrder')}</h2>
            <div className={css['order-lines']}>

                {priceList.map((item) =>
                    item.name !== 'total' &&
                            <div className={css['order-line']} key={item.name}>
                                <div className={css['label']}>{t(item.name)}</div>
                                <div className={css['order-lines']}>{item.price}  euro</div>
                            </div>


                )}


            </div>
            <div className={css['total-row']}>
                <div className={css['order-line']}>
                    <div className={css['label']}>{t('total')}</div>
                    <div className={css['order-lines']}>{priceTotal} euro</div>

                </div>
            </div>
            <div className={css['payment-info']}>
                {t('paymentInfo')}
            </div>

        </div>
    )
}

export default ShoppingBasket;