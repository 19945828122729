import styles from "../../components/Main/Main.module.css";
import React from "react";

export function SuccessElement(props) {
    return(
        <section className={'contentBox '+styles['main-forms']}>
            <div>We have succesfully  received your information, thank you</div>
            <div className="successWrapper">
             {/*   {props.savedContent.map(( step ) =>

                    step.columns.map(( column ) =>
                        column.fields.map(( field ) => {
                                if(field.savedValue){
                                    return <div>{field.prettyName} : {field.savedValue}</div>
                                }
                            }
                        )
                    )

                )}*/}
            </div>

        </section>
    );
}

export default SuccessElement;