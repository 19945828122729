import React, {useContext} from 'react';
import styles from './Header.module.css';
import icon_headset_black from "../../assets/images/icon_headset_black.png"
import {FormContext} from "../../context/FormContext";

function Header( activeStep ) {
   /* console.log(activeStep);*/
    const {currentStep} = useContext(FormContext);


    return (
        <header className={styles['header']}>
            <a href="https://www.atc-comm.com" className={styles['header-logo']}>
                <img src={icon_headset_black} width="30px" height="33px" alt="Logo" />
                ATC-COMM
            </a>
            <div className={styles['header-steps']}>step {currentStep.step} </div>
        </header>
    );
}

export default Header;
